import React from 'react'

const SheffieldersLogo = ({logofill = "#1b1b1b"}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" fill={logofill}>
      <title>sheffielders-logo</title>
      <path d="M105.78,323.91l-4.19.08A3.61,3.61,0,0,1,98,320.78a17.94,17.94,0,0,0-5.46-11.06c-4.24-4.12-8.14-4.24-11-1.32-2.12,2.18-2.76,4.68.46,8.81l3.45,4.36c7.36,9.28,7.18,17.14.66,23.84-7,7.15-17.42,6.67-26.81-2.46-4.82-4.7-7.47-9.78-8.2-15.12a3.59,3.59,0,0,1,3.5-4.05l4.23-.07a3.61,3.61,0,0,1,3.63,3.15,15,15,0,0,0,4.82,8.92c3.9,3.78,7.23,4.13,9.57,1.73,2.68-2.75,2.25-5.17-.57-8.92l-3.34-4.47c-6.91-9.29-7.29-16.8-.61-23.67,7.47-7.67,18.26-7.54,28.23,2.16,5.71,5.56,8.18,11.47,8.76,17.34A3.61,3.61,0,0,1,105.78,323.91Z" />
      <path d="M39.9,247.93l4.62,13.28a3.59,3.59,0,0,0,4.58,2.21l14.79-5.15a3.61,3.61,0,0,1,4.58,2.21l1.63,4.68a3.6,3.6,0,0,1-2.22,4.58L22,285.72a3.6,3.6,0,0,1-4.58-2.21l-1.63-4.68A3.61,3.61,0,0,1,18,274.25l14.18-4.94a3.6,3.6,0,0,0,2.22-4.58l-4.63-13.27a3.6,3.6,0,0,0-4.58-2.22L11,254.19A3.6,3.6,0,0,1,6.45,252L4.82,247.3A3.6,3.6,0,0,1,7,242.72l45.87-16A3.59,3.59,0,0,1,57.48,229l1.63,4.68a3.59,3.59,0,0,1-2.22,4.57l-14.78,5.16A3.58,3.58,0,0,0,39.9,247.93Z" />
      <path d="M51.12,199.4l-48.2-6a3.61,3.61,0,0,1-3.13-4L3.57,158.9a3.59,3.59,0,0,1,4-3.12l3.56.44a3.6,3.6,0,0,1,3.13,4L12,178.7a3.59,3.59,0,0,0,3.12,4l4.2.52a3.6,3.6,0,0,0,4-3.13l1.84-14.82a3.59,3.59,0,0,1,4-3.13l3.48.43a3.61,3.61,0,0,1,3.13,4L34,181.42a3.59,3.59,0,0,0,3.12,4l4.84.59a3.59,3.59,0,0,0,4-3.12l2.28-18.47a3.61,3.61,0,0,1,4-3.13l3.57.44a3.6,3.6,0,0,1,3.13,4l-3.78,30.52A3.6,3.6,0,0,1,51.12,199.4Z" />
      <path d="M64.83,137.55,23.4,112.2a3.59,3.59,0,0,1-1.19-5L37.76,81.84a3.59,3.59,0,0,1,5-1.19l3.06,1.87a3.59,3.59,0,0,1,1.19,5l-9.21,15.06a3.58,3.58,0,0,0,1.19,4.94l3.61,2.21a3.59,3.59,0,0,0,4.94-1.19l7.3-11.92a3.58,3.58,0,0,1,4.94-1.19l3,1.83a3.59,3.59,0,0,1,1.19,4.94l-7.3,11.93A3.59,3.59,0,0,0,57.81,119l13.36,8.18a3.6,3.6,0,0,1,1.19,4.94l-2.59,4.22A3.58,3.58,0,0,1,64.83,137.55Z" />
      <path d="M101.78,88.14l-27.61-40a3.6,3.6,0,0,1,.92-5L99.61,26.24a3.6,3.6,0,0,1,5,.91l2,3a3.6,3.6,0,0,1-.91,5l-14.53,10a3.59,3.59,0,0,0-.92,5l2.41,3.48a3.59,3.59,0,0,0,5,.92l11.5-7.95a3.61,3.61,0,0,1,5,.91l2,2.9a3.6,3.6,0,0,1-.91,5l-11.5,7.94a3.6,3.6,0,0,0-.92,5l8.9,12.88a3.6,3.6,0,0,1-.91,5l-4.08,2.82A3.59,3.59,0,0,1,101.78,88.14Z" />
      <path d="M154,58.9l-.55-2.41a3.58,3.58,0,0,1,2.71-4.3h0a3.6,3.6,0,0,0,2.71-4.31l-6.46-28.51a3.59,3.59,0,0,0-4.3-2.71h0A3.61,3.61,0,0,1,143.76,14l-.54-2.42a3.59,3.59,0,0,1,2.71-4.3L164.78,3a3.59,3.59,0,0,1,4.3,2.71l.55,2.41a3.59,3.59,0,0,1-2.71,4.3h0a3.59,3.59,0,0,0-2.71,4.3l6.46,28.52A3.6,3.6,0,0,0,175,47.91h0a3.6,3.6,0,0,1,4.3,2.71l.54,2.42a3.59,3.59,0,0,1-2.71,4.3l-18.85,4.27A3.59,3.59,0,0,1,154,58.9Z" />
      <path d="M205.5,51.33l7.76-47.95a3.6,3.6,0,0,1,4.13-3l30.36,4.92a3.58,3.58,0,0,1,3,4.12L250.15,13A3.59,3.59,0,0,1,246,16l-18.38-3a3.58,3.58,0,0,0-4.12,3l-.68,4.18a3.58,3.58,0,0,0,3,4.12l14.75,2.39a3.61,3.61,0,0,1,3,4.13L243,34.26a3.58,3.58,0,0,1-4.12,3l-14.75-2.39a3.6,3.6,0,0,0-4.12,3l-.78,4.81a3.59,3.59,0,0,0,3,4.12l18.38,3a3.59,3.59,0,0,1,3,4.12L243,57.4a3.6,3.6,0,0,1-4.13,3l-30.36-4.91A3.6,3.6,0,0,1,205.5,51.33Z" />
      <path d="M266.77,67.62l26.32-40.83a3.59,3.59,0,0,1,5-1.07l4.17,2.68a3.59,3.59,0,0,1,1.07,5L282.82,65.13a3.59,3.59,0,0,0,1.07,5l11.82,7.62a3.59,3.59,0,0,1,1.07,5l-1.94,3a3.61,3.61,0,0,1-5,1.07l-22-14.2A3.59,3.59,0,0,1,266.77,67.62Z" />
      <path d="M353.42,76.34a3.59,3.59,0,0,1,5,1.11l9.35,14.69c7.93,12.47,5.28,25.71-10.36,35.66s-28.76,6.74-36.69-5.73l-9.35-14.69a3.6,3.6,0,0,1,1.1-5Zm-23.64,39.94c3.86,6.07,9.56,7.74,17,3l7.28-4.63c7.49-4.76,8.39-10.63,4.53-16.7l-2.83-4.45a3.59,3.59,0,0,0-5-1.1l-22.79,14.49a3.59,3.59,0,0,0-1.1,5Z" />
      <path d="M344.07,165.11l48.27-5.42a3.59,3.59,0,0,1,4,3.17l3.43,30.56a3.6,3.6,0,0,1-3.17,4l-3.57.4a3.6,3.6,0,0,1-4-3.17L387,176.13a3.6,3.6,0,0,0-4-3.17l-4.21.47a3.59,3.59,0,0,0-3.17,4l1.67,14.85a3.59,3.59,0,0,1-3.17,4l-3.49.4a3.6,3.6,0,0,1-4-3.17L365,178.6a3.59,3.59,0,0,0-4-3.17l-4.84.54a3.59,3.59,0,0,0-3.17,4l2.07,18.5a3.6,3.6,0,0,1-3.17,4l-3.57.4a3.59,3.59,0,0,1-4-3.17l-3.44-30.57A3.6,3.6,0,0,1,344.07,165.11Z" />
      <path d="M339.52,235.29l1.63-4.68a3.58,3.58,0,0,1,4.57-2.21l45.89,15.91a3.59,3.59,0,0,1,2.22,4.57l-7.48,21.59C383,280.2,374,284,364,280.56c-6-2.08-10.19-6.07-11.45-11.64a3.58,3.58,0,0,0-4-2.74l-14.93,1.95a3.6,3.6,0,0,1-3.87-4.74l1.78-5.12a3.58,3.58,0,0,1,3-2.39l18-2a3.62,3.62,0,0,0,3-2.4l.93-2.68a3.6,3.6,0,0,0-2.22-4.58l-12.53-4.34A3.61,3.61,0,0,1,339.52,235.29Zm22.89,27.64a3.59,3.59,0,0,0,2.22,4.57l5.13,1.78c3.4,1.18,5.94-.3,7.12-3.7l3-8.68a3.6,3.6,0,0,0-2.22-4.58l-6.94-2.4a3.6,3.6,0,0,0-4.58,2.22Z" />
      <path d="M322.55,295.2l0,4.19a3.59,3.59,0,0,1-3.26,3.59,17.94,17.94,0,0,0-11.12,5.32c-4.18,4.18-4.35,8.08-1.47,11,2.15,2.14,4.64,2.82,8.81-.34l4.41-3.4c9.37-7.23,17.22-6.95,23.84-.35,7.06,7.06,6.44,17.51-2.82,26.78-4.75,4.76-9.87,7.34-15.21,8a3.58,3.58,0,0,1-4-3.55l0-4.23a3.6,3.6,0,0,1,3.2-3.59,15,15,0,0,0,9-4.7c3.84-3.85,4.23-7.18,1.86-9.55-2.71-2.71-5.14-2.31-8.93.46l-4.51,3.27c-9.38,6.79-16.89,7.07-23.67.3-7.57-7.57-7.3-18.36,2.53-28.19,5.64-5.65,11.58-8,17.45-8.53A3.6,3.6,0,0,1,322.55,295.2Z" />
      <path d="M199.62,377a175.39,175.39,0,0,1-76.31-17.22,3.61,3.61,0,0,1-1.66-4.86l1.78-3.54a3.6,3.6,0,0,1,4.76-1.62,166.5,166.5,0,0,0,142.86,0,3.58,3.58,0,0,1,4.76,1.62l1.78,3.54a3.6,3.6,0,0,1-1.67,4.86A175.34,175.34,0,0,1,199.62,377Z" />
    </svg>
  )
}

export default SheffieldersLogo
